<template>
  <table
    style="width: 100%"
    class="table table-striped- table-bordered table-hover table-checkable"
    v-bind:id="table.tableName"
  ></table>
</template>

<script>
import { isNullOrUndefined } from 'util';
export default {
  props: {
    table: { type: Object },
    exportColumns: { type: Array },
    isChecked: { type: Boolean, default: false }
  },
  methods: {
    getDatatables: function () {
      var vx = this;
      var tableParams = this.table;

      $.fn.dataTable.Api.register("column().title()", function () {
        return $(this.header())
          .text()
          .trim();
      });

      var dtTables, settingsOInstance;
      dtTables = $("#" + tableParams.tableName).DataTable({
        // responsive: !0,
        responsive: true,
        dom:
          "<'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",
        lengthMenu: [5, 10, 25, 50],
        pageLength: 10,
        language: {
          lengthMenu: vx.$i18n.t("datatables.showing") + " _MENU_",
          info: vx.$i18n.t("datatables.showingResult", {
            start: "_START_",
            end: "_END_"
          }),
          infoFiltered: vx.$i18n.t("datatables.showingResultFiltered", {
            max: "_MAX_"
          }),
          infoEmpty: vx.$i18n.t("datatables.showingInfoEmpty"),
          zeroRecords: vx.$i18n.t("datatables.showingZeoRecords")
        },
        searchDelay: 500,
        processing: !0,
        serverSide: 0,
        ordering: !isNullOrUndefined(tableParams.ordering) ? tableParams.ordering : true,
        order:  !isNullOrUndefined(tableParams.order) ? tableParams.order : [[0, "desc"]],
        data: tableParams.tableDataResult,
        // ajax: tableParams.tableRequestForAjax,
        columns: tableParams.tableColumns,
        initComplete: function (settings) {
          settingsOInstance = settings.oInstance;
        },
        columnDefs: tableParams.tableColumnDefs,
        createdRow: (tableParams.createdRow) ? tableParams.createdRow : null
      });

      $(".dt-buttons.btn-group").remove();

      var buttons = new $.fn.dataTable.Buttons(dtTables, {
        buttons: tableParams.buttons
      })
        .container()
        .appendTo($("#btn_export_container"));

      vx.dataTableFilter(dtTables, settingsOInstance);
    },
    dataTableFilter: function (t, e) {
      var vx = this;
      var tableParams = this.table;

      var a = $('<tr class="filter"></tr>').appendTo($(t.table().header()));
      e.api()
        .columns()
        .every(function () {
          var fx = this;

          var e;

          var column_type;
          var field;
          var column_type2 = _.first(
            _.filter(tableParams.tableFilter, function (data) {
              return data.title == fx.title();
            })
          );

          if (column_type2 != undefined) {
            column_type = column_type2.type;
            field = (column_type2.field != undefined) ? column_type2.field : null;
          }

          switch (column_type) {
            case "text":
              e = $(
                '<input type="text" class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" data-col-index="' +
                this.index() +
                '"/>'
              );
              break;
            case "checkbox":
              var chk = (vx.isChecked) ? "checked" : "";
              e = $(
                '<div class="text-center"><label class="kt-checkbox"><input type="checkbox" ' + chk + ' class="kt_chk_title kt-flag_' + tableParams.tableName + '" data-col-index="' +
                this.index() +
                ' "/> &nbsp; <span></span> </label></div>'
              );
              break;
            case "combobox":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" title="Select" data-col-index="' +
                this.index() +
                '"><option value="">Semua</option></select>'
              )),
                this.data()
                  .unique()
                  .sort()
                  .each(function (t, a) {
                    $(e).append('<option value="' + t + '">' + t + "</option>");
                  });
              break;
            case "combobox2":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" title="Select" data-col-index="' +
                this.index() +
                '"><option value="">Semua</option></select>'
              ))
              let temp = [];
              this.data().map(x => temp.push(x[field]));
              const uniqueTemp = vx.getUnique(temp);
              uniqueTemp.forEach(value => {
                $(e).append('<option value="' + value + '">' + value + "</option>");
              });
              break;
            case "combobox3":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" title="Select" data-col-index="' +
                this.index() +
                '"><option value="">Semua</option></select>'
              )),
                this.data()
                  .unique()
                  .sort()
                  .each(function (t, a) {
                    $(e).append('<option value="' + vx.$i18n.t(field + t) + '">' + vx.$i18n.t(field + t) + "</option>");
                  });
              break;
            case "combobox4":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" title="Select" data-col-index="' +
                this.index() +
                '"><option value="">Semua</option></select>'
              ))
              field.forEach(value => {
                $(e).append('<option value="' + vx.$i18n.t(value) + '">' + vx.$i18n.t(value) + "</option>");
              });
              break;
            case "status":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" title="Select" data-col-index="' +
                this.index() +
                '"><option value="">Semua</option></select>'
              )),
                this.data()
                  .unique()
                  .sort()
                  .each(function (t, a) {
                    var i = {
                      NEW: {
                        title: "Baru",
                        class: "kt-badge--brand"
                      },
                      CONFIRM: {
                        title: "Terkonfirmasi",
                        class: "kt-badge--success"
                      },
                      ARRIVED: {
                        title: "Pasien Datang",
                        class: "kt-badge--primary"
                      },
                      DISPATCH: {
                        title: "Pasien Selesai",
                        class: "kt-badge--success"
                      },
                      CANCEL: {
                        title: "Dibatalkan",
                        class: "kt-badge--danger"
                      }
                    };

                    $(e).append(
                      '<option value="' +
                      i[t].title +
                      '">' +
                      i[t].title +
                      "</option>"
                    );
                  });
              break;
            case "boolean":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" title="Select" data-col-index="' +
                this.index() +
                '"><option value="">Semua</option></select>'
              )),
                this.data()
                  .unique()
                  .sort()
                  .each(function (t, a) {
                    var i = {
                      true: {
                        title: "Ya"
                      },
                      false: {
                        title: "Tidak"
                      },
                    };

                    $(e).append(
                      '<option value="' +
                      i[t].title +
                      '">' +
                      i[t].title +
                      "</option>"
                    );
                  });
              break;
            case "ready":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input kt-flag_' + tableParams.tableName + '" title="Select" data-col-index="' +
                this.index() +
                '"><option value="">Semua</option></select>'
              )),
                t
                  .column(7)
                  .data()
                  .unique()
                  .sort()
                  .each(function (value, index) {
                    var i = {
                      true: {
                        title: "Ready",
                        status: "checked"
                      },
                      false: {
                        title: "Belum Ready",
                        status: ""
                      }
                    };

                    $(e).append(
                      '<option value="' +
                      value +
                      '">' +
                      i[value].title +
                      "</option>"
                    );
                  });
              break;
            case "datepicker_today":
              e = $(
                '<div class="input-group input-group-sm date">\
                                                     <div class="input-group-prepend"><span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span></div>\
                                                     <input type="text" class="form-control form-control-sm form-filter kt-input kt-flag_'+ tableParams.tableName + ' datepicker-today-filter" data-col-index="' +
                this.index() +
                '">\
                                                     </div>'
              );
              break;
            case "datepicker":
              e = $(
                '<div class="input-group input-group-sm date">\
                                                     <div class="input-group-prepend"><span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span></div>\
                                                     <input type="text" class="form-control form-control-sm form-filter kt-input kt-flag_'+ tableParams.tableName + ' datepicker-filter" data-col-index="' +
                this.index() +
                '">\
                                                     </div>'
              );
              break;
            case "actions":
              var i = $(
                '<div class="btn-group mr-2 d-none" role="group">\
                                                     <button id="filter_search_'+ tableParams.tableName + '" class="btn btn-brand kt-btn btn-sm kt-btn--icon" title="Search"><span><i class="la la-search"></i></span></button>\
                                                     </div>'
              ),
                s = $(
                  '<div class="btn-group d-none" role="group">\
                                                         <button class="btn btn-secondary kt-btn btn-sm kt-btn--icon" title="Reset"><span><i class="la la-close"></i></span></button>\
                                                         </div>'
                );
              $("<th>")
                .append(i)
                .append(s)
                .appendTo(a),
                $(i).on("click", function (e) {
                  e.preventDefault();
                  var n = {};
                  $(a)
                    .find(".kt-input.kt-flag_" + tableParams.tableName)
                    .each(function () {
                      var t = $(this).data("col-index");
                      n[t]
                        ? (n[t] += "|" + $(this).val())
                        : (n[t] = $(this).val());
                    }),
                    $.each(n, function (e, a) {
                      t.column(e).search(a || "", !1, !1);
                    }),
                    t.table().draw();
                }),
                $(s).on("click", function (e) {
                  e.preventDefault(),
                    $(a)
                      .find(".kt-input.kt-flag_" + tableParams.tableName)
                      .each(function (e) {
                        $(this).val(""),
                          t
                            .column($(this).data("col-index"))
                            .search("", !1, !1);
                      }),
                    t.table().draw();
                });
          }
          "Actions" !== this.title() && $(e).appendTo($("<th>").appendTo(a));
        });
      var n = function () {
        e.api()
          .columns()
          .every(function () {
            this.responsiveHidden()
              ? $(a)
                .find("th")
                .eq(this.index())
                .show()
              : $(a)
                .find("th")
                .eq(this.index())
                .hide();
          });
      };
      var timeOut
      n(),
        (window.onresize = n),
        // Filter
        
        $(".kt-input.kt-flag_" + tableParams.tableName).bind("keyup change", function () {
            if(timeOut) {
              clearTimeout(timeOut)
            }
            timeOut = setTimeout(()=> {
              $("#filter_search_" + tableParams.tableName).trigger("click");
              const kt = $('#kt_subheader')
              const add = '<input type="text" id="null-data">'
              kt.append(add)
              $('#null-data').focus()
              $('#null-data').remove()
              $(this).focus()
            }, 500)
        }),
        $(".kt_chk_title.kt-flag_" + tableParams.tableName).bind("click", function () {
          var isChecked = $(".kt_chk_title.kt-flag_" + tableParams.tableName).is(':checked')
          vx.$emit("checkbox", isChecked)
        }),
        $("#filter_search_" + tableParams.tableName).trigger("click"),
        ($.fn.datepicker.dates["id"] = {
          days: [
            "Minggu",
            "Senin",
            "Selasa",
            "Rabu",
            "Kamis",
            "Jumat",
            "Sabtu"
          ],
          daysShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
          daysMin: ["Mg", "Sn", "Sl", "Rb", "Km", "Jm", "Sb"],
          months: [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember"
          ],
          monthsShort: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Agt",
            "Sep",
            "Okt",
            "Nov",
            "Des"
          ],
          weekStart: 1
        }),
        $(".datepicker-today-filter")
          .datepicker({
            language: "id",
            todayHighlight: true,
            format: tableParams.dateFormat ? tableParams.dateFormat : "dd/mm/yyyy"
          })
          .datepicker("setDate", "now"),
        $(".datepicker-filter").datepicker({
          language: "id",
          todayHighlight: true,
          format: tableParams.dateFormat ? tableParams.dateFormat : "dd/mm/yyyy"
        });

    },
    getUnique: function (val) {
      return [...new Set(val)];
    }
  },
  mounted() {
    this.getDatatables();    
  }
};
</script>
